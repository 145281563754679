"use client";
import { useAuth0, User } from '@auth0/auth0-react';
import {
  Avatar,
  Button,
  Flex,
  Group,
  Image,
  MediaQuery,
  Navbar,
  ScrollArea,
  Text,
} from '@mantine/core';
import { LinksGroup } from './LinksGroup';
import { sideBarLinks } from './navItems';
import { ReactNode } from 'react';
import { useMenuState } from './NavbarContext';
import { faDashboard } from '@fortawesome/sharp-light-svg-icons';

function UnauthenticatedItems() {
  const { loginWithRedirect } = useAuth0();

  function logIn() {
    return loginWithRedirect();
  }

  return (
    <Button fullWidth color={'blue'} onClick={logIn}>
      Log in
    </Button>
  );
}

function AuthenticatedItems({ user }: { user: User }) {
  const { logout, loginWithRedirect } = useAuth0();
  return (
    <>
      <Group position='apart' mb={12}>
        <Avatar
          size={'md'}
          aria-label='avatar'
          src={user.picture}
          alt='avatar'
          radius={'xl'}
        />

        <div style={{ flex: 1 }}>
          <Text size='lg' weight={500}>
            {user.nickname}
          </Text>

          <Text color='dimmed' size='md'>
            {user.email}
          </Text>
        </div>
      </Group>
      <Flex
        gap='xs'
        justify='center'
        align='center'
        direction='column'
        wrap='wrap'
      >
        <Button
          onClick={() => logout()}
          color={'red'}
          fullWidth
          compact
          variant={'light'}
        >
          Logout
        </Button>
      </Flex>
    </>
  );
}

export type AppSideBarProps = {
  children?: ReactNode;
};

export function AppSideBar({ children }: AppSideBarProps) {
  const { opened } = useMenuState();
  const { isAuthenticated, user } = useAuth0();

  return (
    <Navbar width={{ md: 300 }} p='md' hidden={!opened}>
      <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
        <Navbar.Section mb={12}>
          <Group position='apart'>
            <Image
              maw={200}
              radius='md'
              src='/images/logo-black.png'
              alt='Meet PAT'
            />
          </Group>
        </Navbar.Section>
      </MediaQuery>

      <LinksGroup label={'Home'} links={'/'} icon={faDashboard} />

      {children && children}

      <Navbar.Section grow component={ScrollArea}>
        {sideBarLinks.map((item, index) => (
          <LinksGroup key={index} {...item} />
        ))}
      </Navbar.Section>

      <Navbar.Section p={6}>
        {isAuthenticated ? (
          <AuthenticatedItems user={user!} />
        ) : (
          <UnauthenticatedItems />
        )}
      </Navbar.Section>
    </Navbar>
  );
}
