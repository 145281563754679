'use client';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { GetKnownTagsDocument, GetKnownTagsQuery } from '@/graphql';

type WaterStationFilterPanelContextProps = {
  grouping: string | null;
  setGrouping: Dispatch<SetStateAction<string | null>>;
};

const WaterStationFilterSettingsContext =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createContext<WaterStationFilterPanelContextProps>(undefined!);

export const useWaterStationFilterSettings: () => WaterStationFilterPanelContextProps =
  () => {
    return useContext(WaterStationFilterSettingsContext);
  };

export const WaterStationFilterSettingsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const q = useQuery<GetKnownTagsQuery>(GetKnownTagsDocument);
  const [grouping, setGrouping] = useState<string | null>(
    localStorage.getItem('water-station-grouping')
  );

  useEffect(() => {
    // storing input name
    grouping !== null
      ? localStorage.setItem('water-station-grouping', grouping)
      : localStorage.removeItem('water-station-grouping');
  }, [grouping]);

  return (
    <WaterStationFilterSettingsContext.Provider
      value={{ grouping, setGrouping }}
    >
      {children}
    </WaterStationFilterSettingsContext.Provider>
  );
};
