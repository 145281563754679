"use client";
import { ReactNode } from 'react';
import { AppShell, useMantineTheme } from '@mantine/core';
import { AppSideBar } from './AppSideBar';
import { useMediaQuery } from '@mantine/hooks';
import { AppHeader } from '@/components/Layout/AppHeader';

export function MainShell({ children }: { children: ReactNode }) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  return (
    <AppShell
      layout={isMobile ? 'default' : 'alt'}
      navbar={<AppSideBar />}
      header={isMobile ? <AppHeader /> : undefined}
    >
      {children}
    </AppShell>
  );
}
