"use client"
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache,} from '@apollo/client';
import {useAuth0} from '@auth0/auth0-react';
import React, {ReactNode} from 'react';
import {setContext} from '@apollo/client/link/context';
import {relayStylePagination} from '@apollo/client/utilities';

type ApolloProviderWithAuth0Props = {
  children: ReactNode;
};

const ApolloProviderWithAuth0 = ({
                                   children,
                                 }: ApolloProviderWithAuth0Props) => {
  const {getAccessTokenSilently, isLoading} = useAuth0();


  const client = React.useMemo(() => {
    const httpLink = new HttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    });

    const authLink = setContext(async (_, {headers, ...rest}) => {
      const token = await getAccessTokenSilently();
      return {
        ...rest,
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });
    return new ApolloClient({
      link: authLink.concat(httpLink),
      connectToDevTools: true,
      defaultOptions: {
        query: {
          pollInterval: 30_000,
          fetchPolicy: "cache-first"
        },
      },
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              waterStations: relayStylePagination(),
              tenants: relayStylePagination(),
            },
          },
          UntaggedWaterStationGroup: {
            fields: {
              waterStations: relayStylePagination(),
            },
          },
          TaggedWaterStationGroup: {
            fields: {waterStations: relayStylePagination()},
          },
          Tenant: {
            fields: {
              waterStations: relayStylePagination(),
            },
          },
        },
      }),
    });
  }, [getAccessTokenSilently]);


  return (
    <ApolloProvider client={client}>
      {!isLoading && children}
    </ApolloProvider>
  );
};

export {ApolloProviderWithAuth0};
