"use client";
import { useState } from 'react';
import {
  Box,
  Collapse,
  createStyles,
  Group,
  rem,
  Text,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core';
import { LinksGroupProps } from './navItems';
import Link from 'next/link';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/sharp-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.md,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(30),
    fontSize: theme.fontSizes.md,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },
  chevron: {
    transition: 'transform 200ms ease',
  },
}));

export function LinksGroup({
  icon,
  label,
  initiallyOpened,
  links,
}: LinksGroupProps) {
  const { classes } = useStyles();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const items = (hasLinks ? links : []).map((link) => (
    <Text
      component={Link}
      className={classes.link}
      href={link.link}
      key={link.label}
    >
      {link.label}
    </Text>
  ));

  if (!hasLinks) {
    return (
      <UnstyledButton component={Link} className={classes.control} href={'/'}>
        <Group position='apart' spacing={0}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant='light' size={30}>
              <FontAwesomeIcon icon={icon} size={'lg'} />
            </ThemeIcon>
            <Box ml='md'>
              <Text>{label}</Text>
            </Box>
          </Box>
        </Group>
      </UnstyledButton>
    );
  }

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={classes.control}
      >
        <Group position='apart' spacing={0}>
          {hasLinks && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ThemeIcon variant='light' size={30}>
                <FontAwesomeIcon icon={icon} size={'lg'} />
              </ThemeIcon>
              <Box ml='md'>
                <Text>{label}</Text>
              </Box>
            </Box>
          )}

          <FontAwesomeIcon icon={opened ? faChevronDown : faChevronRight} />
        </Group>
      </UnstyledButton>
      <Collapse in={opened}>{items}</Collapse>
    </>
  );
}
