"use client";
import {
  Burger,
  Flex,
  Header as H,
  Image,
  MediaQuery,
  useMantineTheme,
} from '@mantine/core';
import { useMenuState } from './NavbarContext';

export const AppHeader = () => {
  const { opened, setOpened } = useMenuState();
  const theme = useMantineTheme();
  return (
    <MediaQuery largerThan='md' styles={{ display: 'none' }}>
      <H height={50} variant={'outline'} pl={'md'}>
        <Flex
          direction={'row'}
          align={'center'}
          justify={'space-between'}
          h={'100%'}
        >
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size='sm'
            color={theme.colors.gray[6]}
          />
          <Image
            height={32}
            width={300}
            fit={'scale-down'}
            src='/images/logo-black.png'
            alt='Meet PAT'
          />
          <div></div>
        </Flex>
      </H>
    </MediaQuery>
  );
};
