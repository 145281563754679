"use client";
import {Breadcrumbs as MantineCrumbs, MediaQuery, Skeleton, Text,} from '@mantine/core';
import {usePathname} from 'next/navigation';
import {DocumentNode, useFragment} from '@apollo/client';
import {BreadcrumbRoleFragmentDoc, BreadcrumbTenantFragmentDoc, BreadcrumbWaterStationFragmentDoc,} from '@/graphql';
import Link from 'next/link';

type BreadcrumbProps = {
  id: string;
  href: string;
  documentNode: DocumentNode;
  typename: string;
};

function SkeletonBreadcrumb<
  T extends {
    name: string;
  }
>({id, href, documentNode, typename}: BreadcrumbProps) {
  const path = usePathname();
  const {data} = useFragment<T>({
    fragment: documentNode,
    from: {
      __typename: typename,
      id,
    },
  });

  if (data?.name === undefined) {
    return <Skeleton key={href} role={'link'} width={100}/>;
  }

  return (
    <Text
      key={href}
      component={Link}
      href={href}
      color={path === href ? 'dimmed' : 'blue'}
    >
      {data.name ?? typename}
    </Text>
  );
}

function TextBreadcrumb({title, href}: { title: string; href: string }) {
  const path = usePathname();
  const humanized = title
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });

  return (
    <Text
      key={href}
      component={Link}
      href={href}
      color={path === href ? 'dimmed' : 'blue'}
    >
      {humanized}
    </Text>
  );
}

function replace(title: string, href: string, index: number) {
  try {
    const uri = decodeURIComponent(title);
    const decoded = atob(uri);
    if (decoded.startsWith('Tenant'))
      return (
        <SkeletonBreadcrumb
          documentNode={BreadcrumbTenantFragmentDoc}
          href={href}
          key={index}
          id={uri}
          typename={'Tenant'}
        />
      );
    if (decoded.startsWith('Role'))
      return (
        <SkeletonBreadcrumb
          href={href}
          key={index}
          documentNode={BreadcrumbRoleFragmentDoc}
          id={uri}
          typename={'Role'}
        />
      );
    if (decoded.startsWith('WaterStation'))
      return (
        <SkeletonBreadcrumb
          key={index}
          href={href}
          documentNode={BreadcrumbWaterStationFragmentDoc}
          id={uri}
          typename={'WaterStation'}
        />
      );
    return <TextBreadcrumb key={index} title={title} href={href}/>;
  } catch (e) {
    return <TextBreadcrumb key={index} title={title} href={href}/>;
  }
}

function useBreadCrumbs() {
  const path = usePathname()!;
  const asPathWithoutQuery = path.split('?')[0];
  const asPathNestedRoutes = asPathWithoutQuery
    .split('/')
    .filter((v) => v.length > 0);

  return asPathNestedRoutes.map((subPath, idx) => {
    const href = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/');
    const title = subPath;
    return {
      href,
      title,
    };
  });
}

export function Breadcrumbs() {
  const crumbs = useBreadCrumbs();

  if (crumbs.length === 0) {
    return <></>;
  }
  return (
    <MediaQuery smallerThan='md' styles={{display: 'none'}}>
      <MantineCrumbs separator='>' mt='xs' mb={'xl'}>
        {crumbs.map((item, index) => replace(item.title, item.href, index))}
      </MantineCrumbs>
    </MediaQuery>
  );
}
