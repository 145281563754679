"use client";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { usePathname } from 'next/navigation';

interface MenuContext {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

// Create the context
const MenuState = createContext<MenuContext>(undefined!);

// Create a provider component
const MenuStateProvider = ({ children }: { children: ReactNode }) => {
  const [isToggled, setIsToggled] = useState(true);
  const route = usePathname();
  // Hide Menu on route change
  useEffect(() => {
    setIsToggled(false);
  }, [route]);

  // Pass the toggle state and function to the context provider
  const value: MenuContext = {
    opened: isToggled,
    setOpened: setIsToggled,
  };

  return <MenuState.Provider value={value}>{children}</MenuState.Provider>;
};

const useMenuState: () => MenuContext = () => useContext(MenuState)!;

export { useMenuState, MenuStateProvider };
